<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row class="justify-content-between">
          <b-col cols="3">
            <b-form-group
              label="사이트"
              label-for="branch-site"
            >
              <b-form-select
                id="branch-site"
                ref="branch-site"
                v-model="selectedSite"
                :options="authSite"
                class="font-small-3"
                style="color:rgb(229,166,48);"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
          >
            <b-form-group
              label="총판"
              label-for="branch"
            >
              <b-form-select
                id="branch"
                ref="branch"
                v-model="branchSelected"
                :options="branchSelectOptions"
                class="font-small-3"
                style="color:rgb(229,166,48)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-input-group
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                @click="searchData"
              >
                검색
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <div class="d-flex justify-content-between">
          <div>
            <div
              class="text-info small pt-1"
              style="padding-left: 2rem"
            >
              Z: 이월되는 Z`의 값
            </div>
            <div
              class="text-info small"
              style="padding-left: 2rem"
            >
              Z`: 총판포함 하위캐쉬 + 총판포함 하위카지노캐쉬 + 총판포함 하위포인트
            </div>
          </div>
        </div>
        <b-row class="m-1">
          <b-col>
            <b-table
              ref="branchDailyTable"
              responsive
              striped
              hover
              small
              show-empty
              empty-text="조회내역이 없습니다."
              thead-class="text-center"
              tbody-class="text-center"
              sort-by="baseDate"
              :sort-desc="false"
              :items="branchGroupItems"
              :fields="branchGroupFields"
              :tbody-transition-props="transProps"
            >
              <template #cell(site)="data">
                <div
                  class="text-center"
                  style="font-size: small;"
                >
                  {{ data.item.site }}
                </div>
              </template>
              <template #cell(branch)="data">
                <div
                  class="text-center"
                  style="font-size: small; min-width: 4rem;"
                >
                  {{ data.item.branch }}
                </div>
              </template>
              <template #head(rollOverCash)>
                이월캐쉬<br><div style="font-size: xx-small !important;">
                  (Z)
                </div>
              </template>
              <template #cell(rollOverCash)="data">
                <div
                  v-b-tooltip.hover.bottom.v-info="'하위캐쉬: ' + data.item.zUserCash + '\n 하위카지노캐쉬: ' + data.item.zUserCashCasino + '\n 하위포인트: ' + data.item.zUserPoint"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.zUserCash + data.item.zUserCashCasino + data.item.zUserPoint).toLocaleString() }}
                </div>
              </template>
              <template #head(currentCash)>
                현재캐쉬<br><div style="font-size: xx-small !important;">
                  (Z`)
                </div>
              </template>
              <template #cell(currentCash)="data">
                <div
                  v-b-tooltip.hover.bottom.v-info="'하위캐쉬: ' + data.item.zzUserCash + '\n 하위카지노캐쉬: ' + data.item.zzUserCashCasino + '\n 하위포인트: ' + data.item.zzUserPoint"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.zzUserCash + data.item.zzUserCashCasino + data.item.zzUserPoint).toLocaleString() }}
                </div>
              </template>
              <template #head(deposit)>
                충전<br><div style="font-size: xx-small !important;">
                  (A)
                </div>
              </template>
              <template #cell(deposit)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.deposit).toLocaleString() }}
                </div>
              </template>
              <template #head(exchange)>
                환전<br><div style="font-size: xx-small !important;">
                  (B)
                </div>
              </template>
              <template #cell(exchange)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.exchange).toLocaleString() }}
                </div>
              </template>
              <template #head(balance)>
                충환<br><div style="font-size: xx-small !important;">
                  (C=A-B)
                </div>
              </template>
              <template #cell(balance)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.balance).toLocaleString() }}
                </div>
              </template>
              <template #head(accProfit)>
                하부 정산금<br><div style="font-size: xx-small !important;">
                  (D=C-Z`+Z*R)
                </div>
              </template>
              <template #cell(accProfit)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.accProfit).toLocaleString() }}
                </div>
              </template>
              <template #head(profit)>
                총판 정산금<br><div style="font-size: xx-small !important;">
                  (E=C-Z`+Z*R)
                </div>
              </template>
              <template #cell(profit)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.profit).toLocaleString() }}
                </div>
              </template>
              <template #head(totalProfit)>
                총판 정산 합계<br><div style="font-size: xx-small !important;">
                  (F=D+E)
                </div>
              </template>
              <template #cell(totalProfit)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.totalProfit).toLocaleString() }}
                </div>
              </template>
              <template #head(totalProfitRunning)>
                총판 누적정산<br><div style="font-size: xx-small !important;">
                  (G=F+F`)
                </div>
              </template>
              <template #cell(totalProfitRunning)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.totalProfitRunning).toLocaleString() }}
                </div>
              </template>
              <template #head(branchCashOut)>
                총판 출금액<br><div style="font-size: xx-small !important;">
                  (H)
                </div>
              </template>
              <template #cell(branchCashOut)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem; color: orangered"
                >
                  {{ Number(data.item.branchCashOut).toLocaleString() }}
                </div>
              </template>
              <template #head(branchCashBalance)>
                총판 출금가능<br><div style="font-size: xx-small !important;">
                  (I=G-H)
                </div>
              </template>
              <template #cell(branchCashBalance)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.branchCashBalance).toLocaleString() }}
                </div>
              </template>
              <template #cell(type)="data">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon"
                  size="sm"
                  :variant="data.item.type === 'ok' ? 'secondary' : 'primary'"
                  :disabled="data.item.type === 'ok'"
                  @click="updateStatusCommission(data.item)"
                >
                  <feather-icon
                    :icon="data.item.type === 'ok' ? 'CheckIcon' : 'DollarSignIcon'"
                    size="14"
                  />
                </b-button>
              </template>
              <template
                v-if="branchGroupItems.length > 0"
                slot="bottom-row"
              >
                <td
                  colspan="2"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  Total
                </td>
                <td style="background-color: #24233a; color:rgb(196,186,138)" />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('deposit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('exchange')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('balance')).toLocaleString() }}
                </td>
                <td style="background-color: #24233a; color:rgb(196,186,138)" />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accProfit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('profit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalProfit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalProfitRunning')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('branchCashOut')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('branchCashBalance')).toLocaleString() }}
                </td>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormDatepicker,
  BCardText,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_BRANCH_SELECT_OPTIONS,
  FETCH_BRANCH_BALANCE_GROUP,
} from '@/store/branch/action'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'
const fmt3 = 'YYYY-MM-DD'

const branchStore = createNamespacedHelpers('branchStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'BranchCalculateGroup',

  components: {
    BCard,
    BCardBody,
    BTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormDatepicker,
    BCardText,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      selectedSite: localStorage.getItem('authSiteSelected'),
      branchSelected: null,

      cashOutBranchName: JSON.parse(localStorage.getItem('userData')).nickname,
      cashOutBranchDate: moment(new Date()).subtract(1, 'days').format(fmt3),
      cashOutBranchAvailable: 0,
      cashOutBranchAmount: 0,
      cashOutBranchComment: '',
      branchGroupItems: [],
      branchGroupFields: [
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'branch', sortable: false, label: '총판명', thClass: 'col2',
        },
        {
          key: 'rollOverCash', sortable: false, label: '이월캐쉬(Z)', thStyle: { backgroundColor: '#21315a' },
        },
        {
          key: 'deposit', sortable: false, label: '충전(A)', thClass: 'col3', tdClass: 'text-right', formatter: 'getNumberWithCommas',
        },
        {
          key: 'exchange', sortable: false, label: '환전(B)', thClass: 'col3', tdClass: 'text-right', formatter: 'getNumberWithCommas',
        },
        {
          key: 'balance', sortable: false, label: '충환(C=A-B)', thClass: 'col3', tdClass: 'text-right', formatter: 'getNumberWithCommas',
        },
        {
          key: 'currentCash', sortable: false, label: '현재캐쉬(Z`)', thClass: 'col3', tdClass: 'text-right', formatter: 'getNumberWithCommas', thStyle: { backgroundColor: '#21315a' },
        },
        {
          key: 'accProfit', sortable: false, label: '하부 정산금(D=C-Z`+Z*R)', thClass: 'col3', tdClass: 'text-right', formatter: 'getNumberWithCommas',
        },
        {
          key: 'profit', sortable: false, label: '총판 정산금(E=C-Z`+Z*R)', thClass: 'col3', tdClass: 'text-right', formatter: 'getNumberWithCommas',
        },
        {
          key: 'totalProfit', sortable: false, label: '총판 정산 합계(F=D+E)', thClass: 'col3', tdClass: 'text-right', formatter: 'getNumberWithCommas',
        },
        {
          key: 'totalProfitRunning', sortable: false, label: '총판 누적정산금(G=F+F`)', thClass: 'col4',
        },
        {
          key: 'branchCashOut', sortable: false, label: '총판 출금액(H)', thClass: 'col4',
        },
        {
          key: 'branchCashBalance', sortable: false, label: '총판 출금 가능액(I=G-H)', thClass: 'col4', thStyle: { backgroundColor: '#21315a' },
        },
      ],
      periodFrom: '',
      periodTo: '',

      transProps: {
        name: 'fade',
        mode: 'in-out',
      },
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchBranchSelectOptions: 'fetchBranchSelectOptions',
      fetchBranchBalanceGroup: 'fetchBranchBalanceGroup',
    }),
    branchSelectOptions() {
      const resultData = this.fetchBranchSelectOptions.map(option => ({
        value: option,
        text: option.status === 'active'
          ? `[${option.role}] ${option.nickname}`
          : `[${option.role}] ${option.nickname} (${option.status})`,
        selected: option.userid === this.userData.userid,
      }))
      this.branchSelected = this.fetchBranchSelectOptions.find(option => option.userid === this.userData.userid)
      return resultData
    },
  },
  watch: {
    selectedSite: {
      handler(event) {
        localStorage.setItem('authSiteSelected', event || localStorage.getItem('authSiteSelected'))
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        this.init()
      },
      immediate: false,
      deep: false,
    },
    branchSelected: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
    this.init()
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchSelectOptions: FETCH_BRANCH_SELECT_OPTIONS,
      $fetchBranchBalanceGroup: FETCH_BRANCH_BALANCE_GROUP,
    }),
    async init() {
      // this.periodFrom = `${new Date().toISOString().slice(0, 8)}01`
      // this.periodTo = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10)
      this.periodFrom = moment(new Date()).startOf('month').format(fmt1)
      this.periodTo = moment(new Date()).endOf('month').format(fmt1)

      await this.$fetchBranchSelectOptions({
        site: this.selectedSite,
        userid: this.userData.userid,
      })
      await this.fetchData()
    },
    searchData() {
      this.fetchData()
    },
    async fetchData() {
      await this.$fetchBranchBalanceGroup({
        userid: this.branchSelected.userid || this.userData.userid,
        site: this.selectedSite,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
      })
      this.branchGroupItems = this.fetchBranchBalanceGroup
    },

    /* [formatter] */
    getNumberWithCommas(value) {
      if (value == null) {
        return ''
      }
      return parseInt(value).toLocaleString()
    },
    getDateWithDash(value) {
      if (value == null) {
        return ''
      }
      return moment(value, 'YYYYMMDDHHmmss').format(fmt3)
    },
    getTotal(field) {
      return this.branchGroupItems.reduce((acc, item) => acc + item[field], 0)
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.category-select {
  width: 80px;
}
.b-table .table > thead > tr > th {
  white-space: pre-wrap !important;
}
</style>
